import React from 'react'
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire'

import { fireConfig } from './fireConfig'

export const wrapRootElement = ({ element }) => {
  if (typeof window === 'undefined') return <p>Loading...</p>
  return (
    <FirebaseAppProvider firebaseConfig={fireConfig}>
      <SuspenseWithPerf
        fallback={
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              height: '100vh',
              fontSize: '2.5rem',
              fontWeight: '700',
            }}
          >
            Cargando...
          </p>
        }
      >
        {element}
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  )
}
